import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, FilterChangedEvent, GridApi, GridOptions, SetFilterValuesFuncParams, SortModelItem } from 'ag-grid-community';
import { LeaEducatorRosterFilters } from 'src/shared/constants';
import { GridCommonService } from 'src/shared/grid/grid-common.service';
import { AgGridSortModel, LeaClient, LeaEducatorRosterDto, RemoveEducatorFromRosterCommand, } from 'src/shared/services/api.service';
import { EducatorRosterCertResultsComponent } from './educator-roster-cert-results/educator-roster-cert-results.component';
import { RosterActionsCellRendererComponent } from './roster-actions-cell-renderer/roster-actions-cell-renderer.component';
import { Router } from '@angular/router';
import { ConfirmationModalComponent } from 'src/shared/confirmation-modal/confirmation-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LeaRosterQuicklinkFilterModelsDictionary } from '../filter-models';
import { AuthService } from 'src/auth/services/auth.service';
import { ObscuredAgGridCellRendererComponent } from 'src/shared/obscured-ag-grid-cell-renderer/obscured-ag-grid-cell-renderer.component';

@Component({
  selector: 'lea-educator-roster-grid',
  templateUrl: './educator-roster-grid.component.html',
  styles: ``
})
export class EducatorRosterGridComponent {
  constructor(
    private gridCommonService: GridCommonService,
    private leaClient: LeaClient,
    private router: Router,
    private modalService: NgbModal,
    authService: AuthService) {
      this.canApprovePd = authService.hasPolicy('canVerifyProfessionalDevelopment');
    }

  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;
  @Output() quickFilterChanged = new EventEmitter<LeaEducatorRosterFilters | undefined>();
  get gridApi(): GridApi {
    return this.agGrid.api;
  }

  get gridHeight() {
    return (window.innerHeight - 300).toString() + 'px';
  }

  sortModel!: AgGridSortModel[];
  quickFilter?: {};
  filters!: {};
  ivpStatuses: string[] = [];
  canApprovePd: boolean;

  MyEducatorRosterFilters = LeaEducatorRosterFilters;
  errorEmitter = new EventEmitter<any>();


  getColDefs(): ColDef[] {
    const colDefs: ColDef[] = [
      {
        field: 'selectionBox',
        headerName: '',
        sortable: false,
        filter: false,
        checkboxSelection: true,
        suppressMenu: true,
        minWidth: 50,
        maxWidth: 50
      },
      {
        headerName: '',
        cellRenderer: 'agGroupCellRenderer',
        floatingFilter: false,
        filter: false,
        width: 50,
      },
      {
        field: 'personId',
        hide: true,
      },
      {
        field: 'ein',
        headerName: 'EIN',
        filter: 'agTextColumnFilter',
        cellRenderer: (params: any) => `<a href="/lea/educators/${params.value}" target="_blank">${params.value}</a>`,
        width: 135
      },
      {
        field: 'lastName',
        filter: 'agTextColumnFilter',
        cellRenderer: ObscuredAgGridCellRendererComponent,
        headerName: 'Last Name',
      },
      {
        field: 'firstName',
        filter: 'agTextColumnFilter',
        cellRenderer: ObscuredAgGridCellRendererComponent,
        headerName: 'First Name',
      },
      {
        field: 'middleInitial',
        filter: 'agTextColumnFilter',
        headerName: 'Middle Initial',
        minWidth: 65,
        maxWidth: 135
      },
      {
        field: 'ivpStatus',
        headerName: 'IVP Status',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: (params: any) => {
            this.leaClient.getLeaEducatorRosterSetFilterValues({
              propertyName: 'IVPStatus'
            }).subscribe(x => params.success(x.columnValues));
          }
        },
        cellClassRules: {
          "bg-danger": params => params.data.ivpStatus === 'Invalid',
          "text-white": params => params.data.ivpStatus === 'Invalid'
        }
      },
      {
        field: 'hasDisciplinaryHistory',
        filter: 'agSetColumnFilter',
        headerName: 'Disciplinary History',
        filterParams: {
          debounceMs: 1000,
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Yes' : 'No'
        },
        valueFormatter: (params: any) => params.data.hasDisciplinaryHistory ? 'Yes' : 'No',
        valueGetter: (params: any) => params.data.hasDisciplinaryHistory ? 'Yes' : 'No',
      },
      {
        field: 'needsProfessionalDevelopment',
        filter: 'agSetColumnFilter',
        headerName: 'Needs PD',
        filterParams: {
          debounceMs: 1000,
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Yes' : 'No'
        },
        valueFormatter: (params: any) => params.data.needsProfessionalDevelopment ? 'Yes' : 'No',
        valueGetter: (params: any) => params.data.needsProfessionalDevelopment ? 'Yes' : 'No',
        cellClassRules: {
          "bg-warning": params => params.data.needsProfessionalDevelopment,
          "text-white": params => params.data.needsProfessionalDevelopment
        }
      },
      {
        field: 'hasDeficiencies',
        filter: 'agSetColumnFilter',
        headerName: 'Has Deficiencies',
        filterParams: {
          debounceMs: 1000,
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Yes' : 'No'
        },
        valueFormatter: (params: any) => params.data.hasDeficiencies ? 'Yes' : 'No',
        valueGetter: (params: any) => params.data.hasDeficiencies ? 'Yes' : 'No',
        cellClassRules: {
          "bg-info": params => params.data.hasDeficiencies,
          "text-white": params => params.data.hasDeficiencies
        }
      },
      {
        field: 'leaDescription',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: (params: any) => {
            this.leaClient.getLeaEducatorRosterSetFilterValues({
              propertyName: 'LeaDescription'
            }).subscribe(x => params.success(x.columnValues));
          }
        },
        headerName: 'LEA',
      },
      {
        field: 'schoolDescription',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: (params: any) => {
            this.leaClient.getLeaEducatorRosterSetFilterValues({
              propertyName: 'SchoolDescription'
            }).subscribe(x => params.success(x.columnValues));
          }
        },
        valueFormatter: (params: any) => params.data.schoolDescription ?? 'N/A',
        headerName: 'School',
      },
      {
        headerName: 'Actions',
        sortable: false,
        cellRenderer: RosterActionsCellRendererComponent,
        suppressMenu: true,
        filter: false,
        width: 125
      },
      {
        field: 'missingIVPFingerprint',
        filter: 'agNumberColumnFilter',
        filterParams: { filterOptions: this.gridCommonService.numberFilterOptions },
        hide: true,
      },
      {
        field: 'needsProfessionalDevelopment',
        filter: 'agNumberColumnFilter',
        filterParams: { filterOptions: this.gridCommonService.numberFilterOptions },
        hide: true,
      },
      {
        field: 'hasDeficiencies',
        filter: 'agNumberColumnFilter',
        filterParams: { filterOptions: this.gridCommonService.numberFilterOptions },
        hide: true,
      },
      {
        field: 'hasRenewableCertificates',
        filter: 'agSetColumnFilter',
        filterParams: {
          debounceMs: 1000,
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Yes' : 'No'
        },
        valueFormatter: (params: any) => params.data.hasRenewableCertificates ? 'Yes' : 'No',
        valueGetter: (params: any) => params.data.hasRenewableCertificates ? 'Yes' : 'No',
        hide: true,
      },
    ];

    return colDefs;
  }


  gridOptions: GridOptions<LeaEducatorRosterDto> = {
    getRowId: (params) => {
      return params.data.uniqueId;
    },
    masterDetail: true, // allows for expandable rows
    detailCellRenderer: EducatorRosterCertResultsComponent,
    getRowHeight: params => {
      const isDetailRow = params.node.detail;
      // for all rows that are not detail rows, return nothing
      if (!isDetailRow) { return undefined; }

      // otherwise return height based on number of rows in detail grid (plus the header row)
      const detailPanelHeight = (params.data!.educatorCertificates.length * 42) + 40;
      return detailPanelHeight;
    },
    domLayout: 'normal',
    rowModelType: 'serverSide',
    cacheBlockSize: 100,
    pagination: true,
    paginationPageSize: 20,
    suppressRowClickSelection: false,
    rowSelection: 'multiple',
    defaultColDef: {
      sortable: true,
      resizable: true,
      suppressMovable: true,
      suppressMenu: true,
      floatingFilter: true,
      filter: 'agTextColumnFilter'
    },
    columnDefs: this.getColDefs(),
    isRowSelectable: (params) => {
      return !!params.data && params.data.needsProfessionalDevelopment && this.canApprovePd;
    },
    context: {
      componentParent: this
    },
    serverSideFilterOnServer: true,
    serverSideSortOnServer: true,
    serverSideDatasource: {
      getRows: (params) => {
        params.api.showLoadingOverlay();

        if (params.request.sortModel.length === 0) {
          let defaultSortModel: SortModelItem = {
            colId: 'LastName',
            sort: 'asc'
          };
          params.request.sortModel.push(defaultSortModel);
        }

        const requestPayload = {
          pagination: {
            startRow: params.request.startRow!,
            endRow: params.request.endRow!
          },
          sortModels: params.request.sortModel,
          filterModels: this.filters
        };


        this.leaClient.getLeaEducatorRoster(requestPayload).subscribe({
          next: (data) => {
            params.success({
              rowData: data.rosterEntries,
              rowCount: data.totalRosterEntries,
            });
            params.api.hideOverlay();
          },
          error: () => {
            params.fail();
          },
        });
      },
    },
    onFilterChanged: this.onFilterChanged.bind(this)
  }

  ngOnInit() {
    this.filters = this.gridCommonService.filters;
  }

  resetFilters() {
    if (this.gridApi) {
      this.gridApi.setFilterModel(null);
      this.filters = {};
      this.gridApi.onFilterChanged();
      this.gridApi.refreshServerSide();
      this.quickFilterChanged.emit(undefined);
    }
  }

  viewDetails(personId: number) {
    this.router.navigateByUrl(`lea/educators/${personId}`);
  }

  removeFromRoster(personId: number, educatorName: string, ein: string, schoolDescription: string, leaDescription: string) {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      backdrop: 'static',
      size: 'md',
      centered: true,
    });

    modalRef.componentInstance.header = 'Remove From Roster';
    modalRef.componentInstance.message = schoolDescription
      ? `You are removing ${educatorName} (EIN: ${ein}) from ${schoolDescription} school at ${leaDescription} LEA. Are you sure?`
      : `You are removing ${educatorName} (EIN: ${ein}) from the ${leaDescription} LEA. Are you sure?`;

    modalRef.result.then((result) => {
      if (result) {
        this.confirmRemoveFromRoster(personId);
      }
    });
  }

  confirmRemoveFromRoster(personId: number) {
    let command: RemoveEducatorFromRosterCommand = {
      personId: personId
    }

    this.leaClient.removeEducatorFromRoster(command).subscribe(x => {
      this.gridApi.refreshServerSide();
    })
  }

  filterServiceItems(key: LeaEducatorRosterFilters) {
    this.gridApi.setFilterModel(null);
    this.quickFilter = { [key]: LeaRosterQuicklinkFilterModelsDictionary[key] };
    this.gridApi.onFilterChanged('quickFilter');
  }

  onFilterChanged(event: FilterChangedEvent<LeaEducatorRosterDto>) {
    let filters = {};
    // If the source is quickFilter, then we set it in the 'filterServiceItems' call.
    // This is a bit of a hijack of the filter source, but aggrid doesn't support quick filters in the SSRM.
    if (event.source === 'quickFilter') {
      filters = this.quickFilter!;
    } else {
      filters = event.api.getFilterModel();
    }

    if (filters) {
      this.filters = this.gridCommonService.onFilterChanged(filters);
    }

    const quickFilterKey = this.gridCommonService.bindQuickFilters(this.filters,
      LeaEducatorRosterFilters, LeaRosterQuicklinkFilterModelsDictionary);

    this.quickFilterChanged.emit(quickFilterKey as LeaEducatorRosterFilters);
  }
}
